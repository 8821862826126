import { GetMainPageBannersQueryParamsType, useGetMainPageBanners } from '@/api/kubik/monolith'
import type { IRegion } from '@/types'
import type { TProductsPromoType } from './types'

export const useInputsPromoBannerApi = (type: TProductsPromoType, region: IRegion) => {
  const { data, isLoading, isSuccess, isError } = useGetMainPageBanners(
    {
      regionCode: region.code,
      type: type === 'agricultural' ? GetMainPageBannersQueryParamsType.INPUTS_FARMING : GetMainPageBannersQueryParamsType.INPUTS_MACHINERY,
    },
    { query: { staleTime: Infinity } },
  )

  return { data, isLoading, isSuccess, isError }
}
