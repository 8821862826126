import type { TMessages } from '@/types/TMessages'

export const messages = {
  createRequest: 'Создать заявку',
  noBuyersButton: 'Хочу больше покупателей в этом регионе',
  noBuyersAlertLabel: 'В выбранном регионе ещё нет покупателей для этой культуры',
  deliveryBasisLabel: 'Базис поставки',
  proteinLabel: 'Протеин, не менее',
  supplierStoreLabel: 'Название ж/д станции',
  yourStoreLabel: 'Адрес вашего склада',
  elevatorRegionLabel: 'Регион элеватора',
  elevatorIdLabel: 'Название элеватора',
  desiredPriceLabel: 'Цена (без НДС)',
  volumeLabel: 'Объём партии',
  volumeUnits: 'т',
  priceUnit: 'руб./т',
  proteinUnit: '%',
  proteinIncorrectNumberErrorText: 'Введите число между {minValue} и {maxValue}%',
  requiredFieldErrorText: 'Необходимо заполнить',
} satisfies TMessages
