import type { FC } from 'react'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { useGetCatalogBestOffers } from '@/api/kubik/monolith'
import { useOnProductCardsViewGtmEvent, useProductCardInteractGtmEvents } from '@/logic/metrika/index/catalog/productCards'
import { getUserRegion } from '@/logic/userRegion'
import { CardSliderBlock, IndexPageSection, SectionBlock, SectionHeading } from '@/pages/IndexPage/components'
import { messages } from './messages'
import './styles.scss'

const BestOffersInputs: FC = () => {
  const { code } = useSelector(getUserRegion())

  const { data: bestOffers, isLoading, isSuccess, isError } = useGetCatalogBestOffers({ regions: code })

  const setRefCallback = useOnProductCardsViewGtmEvent({ blockName: 'mainBlock', products: bestOffers?.data?.products })
  const cardGtmEvents = useProductCardInteractGtmEvents({ blockName: 'mainBlock' })

  const shouldHideBlock = (isSuccess && !bestOffers.data?.products.length) || isError

  if (shouldHideBlock) return null

  return (
    <IndexPageSection>
      <SectionBlock>
        <SectionHeading header={messages.header} />

        <CardSliderBlock
          isLoading={isLoading}
          products={bestOffers?.data?.products}
          gtmEvents={{ onViewCardRefCallback: setRefCallback, ...cardGtmEvents }}
        />
      </SectionBlock>
    </IndexPageSection>
  )
}

export default memo(BestOffersInputs)
