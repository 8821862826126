import { useMemo } from 'react'
import type { IAdaptiveSlidesPerGroup } from '@/utils/hooks/keenSlider/types'
import { useDeviceType } from '@/utils/hooks/useDeviceType'

export const useAdaptiveSlidesPerGroup = (options: IAdaptiveSlidesPerGroup) => {
  const device = useDeviceType()

  const slidesPerGroup = useMemo(() => {
    if (!options) return 1
    let slides: number | undefined

    for (const key in device) {
      if (device[key as keyof typeof device]) {
        slides = options[key as keyof IAdaptiveSlidesPerGroup]

        break
      }
    }

    return slides ?? 1
  }, [device, options])

  return slidesPerGroup
}
