import { useSelector } from 'react-redux'
import { getUserGtmInfo, pushGtmEvent } from '@/logic/metrika/helpers'
import type { TGtmBlockName } from '@/logic/metrika/index/banners/types'
import { useSetElementsRef } from '@/logic/metrika/index/helpers'
import { getUserRegion } from '@/logic/userRegion'
import type { RootState } from '@/redux/interfaces'
import { useObserveElements } from '@/utils/hooks/index/useObserveElements'

const mapState = (state: RootState) => ({
  user: getUserGtmInfo()(state),
  userRegion: getUserRegion()(state),
})

export const useIndexBannerGtmEvent = (blockName: TGtmBlockName) => {
  const { user, userRegion } = useSelector(mapState)

  const onViewBannerSendGtmEvent = (bannerId: string) => {
    pushGtmEvent({
      event: 'userEvent',
      userAuth: user.userAuth,
      userImpersonalization: user.userImpersonalization,
      memberID: user.memberID,
      uid: user.uid,
      regionSelected: userRegion,
      eventCategory: 'interactions',
      eventAction: 'viewing',
      eventStatus: 'success',
      eventNonInteraction: '1',
      blockName,
      eventLabel: bannerId,
    })
  }

  const { attachedRefId, observableElements, setRefCallback } = useSetElementsRef()

  useObserveElements({ observableElements, attachedRefId, onIntersect: onViewBannerSendGtmEvent, shouldSubmitOnEachView: true })

  const clickBannerGtmEvent = (bannerId: string) => {
    pushGtmEvent({
      event: 'userEvent',
      userAuth: user.userAuth,
      userImpersonalization: user.userImpersonalization,
      memberID: user.memberID,
      uid: user.uid,
      regionSelected: userRegion,
      eventCategory: 'interactions',
      eventAction: 'click',
      eventStatus: 'success',
      eventNonInteraction: '0',
      blockName: 'blockBannerMain',
      eventLabel: bannerId,
    })
  }

  return { clickBannerGtmEvent, setRefCallback }
}
