import React from 'react'
import classNames from 'classnames'
import type { IIndexPageSectionProps } from './types'
import './styles.scss'

const IndexPageSection = (props: IIndexPageSectionProps) => {
  const { children, backgroundColor = 'white', className } = props

  return <section className={classNames('indexPageSection', `indexPageSection--${backgroundColor}`, className)}>{children}</section>
}

export default IndexPageSection
