import type { FC } from 'react'
import React, { memo } from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { EIconName, Icon } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import type { ISectionHeadingProps } from './types'
import './styles.scss'

const SectionHeading: FC<ISectionHeadingProps> = props => {
  const { header, children, subHeader, url, className, onClick } = props

  return (
    <div className={classNames('sectionHeading', className)}>
      <div className="sectionHeading__headerWrapper">
        <h2 className="sectionHeading__headerText">
          <CommonLink url={url} spanIfEmpty className="sectionHeading__link" onClick={onClick}>
            {header}
            {url && <Icon name={EIconName.ShevronRight} className="sectionHeading__icon" />}
          </CommonLink>
        </h2>
        {children}
      </div>
      {subHeader && <p className="sectionHeading__subheader">{subHeader}</p>}
    </div>
  )
}

export default memo(SectionHeading)
