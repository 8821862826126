import type { IDropdownOption } from '@frontend/pole-ui/lib/components/Dropdown'
import { useQuery } from '@tanstack/react-query'
import {
  getQualityIndicators,
  getQualityIndicatorsQueryKey,
  getSaleTemplatesForEachCultures,
  getSaleTemplatesForEachCulturesQueryKey,
  useGetElevators,
} from '@/api/kubik/monolith'
import { transformOptions } from '@/components/ui/fields/helpers/Dropdown'
import { useUserStores } from '@/utils/hooks/useUserStores'
import { transformQualityIndicatorsData } from './helpers'

export const useCultureSaleApi = (cultureId: string, currentElevatorRegion?: string) => {
  const { data: deliveryBasisOptions, isLoading: isDeliveryBasisLoading } = useQuery({
    queryKey: getSaleTemplatesForEachCulturesQueryKey({ limit: 1 }),
    queryFn: () => getSaleTemplatesForEachCultures({ limit: 1 }),
    staleTime: Infinity,
    select: response =>
      transformOptions({ options: response.data?.deliveryBasisVariants, valueKey: 'id' }) satisfies IDropdownOption<string>[],
  })

  const { data: elevatorsData, isLoading: isElevatorsLoading } = useGetElevators(
    { regionCode: Number(currentElevatorRegion) },
    { query: { staleTime: Infinity, enabled: currentElevatorRegion !== undefined && !Number.isNaN(currentElevatorRegion) } },
  )

  const { data: qualityIndicators, isLoading: isQualityIndicatorsLoading } = useQuery({
    queryKey: getQualityIndicatorsQueryKey(cultureId),
    queryFn: () => getQualityIndicators(cultureId),
    staleTime: Infinity,
    select: result => transformQualityIndicatorsData(result.data),
  })

  const { locations, isLocationsLoading, userStores } = useUserStores()

  return {
    data: {
      deliveryBasisOptions: deliveryBasisOptions || [],
      elevators: elevatorsData?.data || [],
      userStores,
      locations,
      qualityIndicators,
    },
    loading: { isDeliveryBasisLoading, isElevatorsLoading, isLocationsLoading, isQualityIndicatorsLoading },
  }
}
