import type { FC } from 'react'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { getUserRegion } from '@/logic/userRegion'
import { IndexPageSection, SectionBlock, SectionHeading } from '@/pages/IndexPage/components'
import type { RootState } from '@/redux/interfaces'
import { BecomePartners, Manufacturers, OurPartners } from './components'
import { messages } from './messages'

const mapState = (state: RootState) => ({
  userRegion: getUserRegion()(state),
})

const Partners: FC = () => {
  const { userRegion } = useSelector(mapState)

  return (
    <>
      <IndexPageSection backgroundColor="grey">
        <SectionBlock>
          <SectionHeading header={messages.becomePartnersHeader} />
          <BecomePartners region={userRegion} />
        </SectionBlock>
      </IndexPageSection>

      <IndexPageSection>
        <SectionBlock>
          <SectionHeading header={messages.ourParntersHeader} />
        </SectionBlock>
        <SectionBlock withoutRightMarginMobile>
          <OurPartners />
        </SectionBlock>
      </IndexPageSection>

      <IndexPageSection>
        <Manufacturers />
      </IndexPageSection>
    </>
  )
}

export default memo(Partners)
