import { useState } from 'react'

export const useSwiperArrows = () => {
  const [isBackArrowVisible, setIsBackArrowVisible] = useState(false)
  const [isForwardArrowVisible, setIsForwardArrowVisible] = useState(true)
  const [isSwiperInit, setIsSwiperInit] = useState(false)

  return {
    swiperArrowParams: {
      init: () => setIsSwiperInit(true),
      reachBeginning: () => setIsBackArrowVisible(false),
      reachEnd: () => setIsForwardArrowVisible(false),
      fromEdge: () => {
        setIsBackArrowVisible(true)
        setIsForwardArrowVisible(true)
      },
    },
    isSwiperInit,
    arrows: { isBackArrowVisible, isForwardArrowVisible: isSwiperInit && isForwardArrowVisible },
  }
}
