export const PARTNERS = [
  {
    name: 'fes-agro',
    height: {
      desktop: '75px',
      tablet: '49px',
      mobile: '42px',
    },
  },
  {
    name: 'fosagro',
    height: {
      desktop: '68px',
      tablet: '49px',
      mobile: '42px',
    },
  },
  {
    name: 'sber-logo',
    height: {
      desktop: '47px',
      tablet: '40px',
      mobile: '40px',
    },
  },
  {
    name: 'sber-leasing',
    height: {
      desktop: '21px',
      tablet: '14px',
      mobile: '14px',
    },
  },
  {
    name: 'center-invest',
    height: {
      desktop: '35px',
      tablet: '28px',
      mobile: '28px',
    },
  },
  {
    name: 'rosagro-leasing',
    height: {
      desktop: '39px',
      tablet: '32px',
      mobile: '32px',
    },
  },
  {
    name: 'demetra',
    height: {
      desktop: '80px',
      tablet: '65px',
      mobile: '49px',
    },
  },
  {
    name: 'agrocopoc',
    height: {
      desktop: '65px',
      tablet: '40px',
      mobile: '48px',
    },
  },
  {
    name: 'biotence',
    height: {
      desktop: '65px',
      tablet: '48px',
      mobile: '44px',
    },
  },
  {
    name: 'sativa',
    height: {
      desktop: '49px',
      tablet: '36px',
      mobile: '32px',
    },
  },
  {
    name: 'kas',
    height: {
      desktop: '76px',
      tablet: '48px',
      mobile: '48px',
    },
  },
  {
    name: 'pro-agro',
    height: {
      desktop: '62px',
      tablet: '42px',
      mobile: '38px',
    },
  },
  {
    name: 'innagro',
    height: {
      desktop: '82px',
      tablet: '48px',
      mobile: '48px',
    },
  },
  {
    name: 'southern-house',
    height: {
      desktop: '58px',
      tablet: '48px',
      mobile: '32px',
    },
  },
  {
    name: 'argoveles',
    height: {
      desktop: '72px',
      tablet: '48px',
      mobile: '32px',
    },
  },
  {
    name: 'ahp',
    height: {
      desktop: '58px',
      tablet: '48px',
      mobile: '32px',
    },
  },
]
