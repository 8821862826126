import type { FC } from 'react'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { useSectionHeaderGtmEvent } from '@/logic/metrika/index/sectionHeaders/gtmEvents'
import { getUserRegion } from '@/logic/userRegion'
import { IndexPageSection, News, SectionBlock, SectionHeading } from '@/pages/IndexPage/components'
import type { RootState } from '@/redux/interfaces'
import { EHarvestSaleRoute } from '@/types'
import { Banners, Catalog, MoreOutputsOffers } from './components'
import { messages } from './messages'
import './styles.scss'

const mapState = (state: RootState) => ({
  userRegion: getUserRegion()(state),
})

const Outputs: FC = () => {
  const { userRegion } = useSelector(mapState)
  const { onClickSectionHeaderGtmEvent } = useSectionHeaderGtmEvent('blockOutputs')

  return (
    <>
      <IndexPageSection>
        <SectionBlock className="outputs__headerWrapper">
          <SectionHeading
            header={messages.header}
            subHeader={messages.subheader}
            onClick={onClickSectionHeaderGtmEvent}
            url={EHarvestSaleRoute.main}
          />
          <Banners region={userRegion} />
        </SectionBlock>
        <Catalog />
      </IndexPageSection>

      <MoreOutputsOffers />

      <IndexPageSection backgroundColor="grey">
        <News type="outputs" />
      </IndexPageSection>
    </>
  )
}

export default memo(Outputs)
