import type { FC } from 'react'
import React, { memo } from 'react'
import { useGetSaleTemplatesOffersList } from '@/api/kubik/monolith'
import { SingleAdditionalOffer } from './components'

const MoreOutputsOffers: FC = () => {
  const { data: moreOffersData, isLoading, isSuccess, isError } = useGetSaleTemplatesOffersList({ query: { staleTime: Infinity } })
  const shouldHideBlock = (isSuccess && !moreOffersData.data?.offers.length) || isError

  if (shouldHideBlock) return null

  return (
    <>
      {moreOffersData?.data?.offers.map(offer =>
        offer.saleRequestTemplate.length ? (
          <SingleAdditionalOffer key={offer.id} offerTitle={offer.title} products={offer.saleRequestTemplate} isLoading={isLoading} />
        ) : null,
      )}
    </>
  )
}

export default memo(MoreOutputsOffers)
