import type { FC } from 'react'
import React from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { EIconName, Icon } from '@frontend/pole-ui/lib/components/Icon'
import { getMinioImagePath } from '@/utils/images'
import type { IServicesCardProps } from './types'
import './styles.scss'

const ServicesCard: FC<IServicesCardProps> = props => {
  const { header, subHeader, url, image, onCardClick } = props

  const handledImagePath = getMinioImagePath({ src: image })

  return (
    <article className="servicesCard" style={{ backgroundImage: `url(${handledImagePath})` }}>
      <CommonLink url={url} className="servicesCard__link" onClick={onCardClick}>
        <h3 className="servicesCard__header">
          {header}
          <Icon name={EIconName.ShevronRight} className="servicesCard__icon" />
        </h3>
        <p className="servicesCard__subheader">{subHeader}</p>
      </CommonLink>
    </article>
  )
}

export default ServicesCard
