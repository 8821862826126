import React from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { MainPageSliderBannerDataSpecialAction } from '@/api/kubik/monolith'
import { Image } from '@/components/ui'
import { useAuthenticateUser } from '@/components/ui/AuthButton/hooks'
import type { ISliderBannerProps } from '@/pages/IndexPage/components/SliderBanner/types'
import { scrollTo } from '@/utils/helpers'
import { getMinioImagePath } from '@/utils/images'
import './styles.scss'

const SliderBanner = (props: ISliderBannerProps) => {
  const { banner, gtmSlideTracker, index, onClick } = props

  const { isAuthenticated, openAuthModal } = useAuthenticateUser()
  const isAuthBanner = banner.specialAction === MainPageSliderBannerDataSpecialAction.REGISTRATION_FORM
  const isUrlBanner = banner.specialAction === MainPageSliderBannerDataSpecialAction.URL && banner.url && !banner.url.startsWith('#')

  const onBannerClick = () => {
    if (isAuthBanner) openAuthModal()

    if (banner.url && banner.url.startsWith('#')) scrollTo(banner.url, undefined, false)
    onClick?.(banner.id)
  }

  if (isAuthBanner && isAuthenticated) return null

  return (
    <div className="sliderBanner__slide" ref={node => gtmSlideTracker({ node, index, productId: banner.id })}>
      <CommonLink spanIfEmpty className="sliderBanner__link" url={isUrlBanner ? banner.url : undefined} onClick={onBannerClick} />
      <picture>
        <source srcSet={getMinioImagePath({ src: banner.mobileImage })} media="(max-width: 479px)" />
        <Image src={banner.webImage} className="sliderBanner__image" />
      </picture>
    </div>
  )
}

export default SliderBanner
