import type { FC } from 'react'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { FinancingConsulting } from '@/components/composed/financing'
import { useSectionHeaderGtmEvent } from '@/logic/metrika/index/sectionHeaders/gtmEvents'
import { getUserRegion } from '@/logic/userRegion'
import { IndexPageSection, SectionBlock, SectionHeading } from '@/pages/IndexPage/components'
import type { RootState } from '@/redux/interfaces'
import { EFinancingOfferRoute } from '@/types'
import { AllFinancingServicesButton, LoanBanner, LoanList } from './components'
import { messages } from './messages'
import './styles.scss'

const mapState = (state: RootState) => ({
  userRegion: getUserRegion()(state),
})

const Ifa: FC = () => {
  const { onClickSectionHeaderGtmEvent } = useSectionHeaderGtmEvent('blockFinance')
  const { userRegion } = useSelector(mapState)

  return (
    <>
      <IndexPageSection>
        <SectionBlock>
          <SectionHeading
            header={messages.sectionHeader}
            subHeader={messages.sectionSubheader}
            onClick={onClickSectionHeaderGtmEvent}
            url={EFinancingOfferRoute.main}
          />
          <LoanBanner region={userRegion} className="indexLoans__banner" />
          <LoanList />
        </SectionBlock>
      </IndexPageSection>

      <IndexPageSection backgroundColor="grey">
        <SectionBlock className="indexLoans__financingConsultingFormWrapper">
          <FinancingConsulting />
        </SectionBlock>
        <SectionBlock>
          <AllFinancingServicesButton />
        </SectionBlock>
      </IndexPageSection>
    </>
  )
}

export default memo(Ifa)
