import { useSelector } from 'react-redux'
import { getUserGtmInfo, pushGtmEvent } from '@/logic/metrika/helpers'
import { getUserRegion } from '@/logic/userRegion'
import type { RootState } from '@/redux/interfaces'

const mapState = (state: RootState) => ({
  user: getUserGtmInfo()(state),
  userRegion: getUserRegion()(state),
})

export const useIndexSearchGtmEvent = () => {
  const { user, userRegion } = useSelector(mapState)

  const onSearchInputGtmEvent = () => {
    pushGtmEvent({
      event: 'userEvent',
      userAuth: user.userAuth,
      userImpersonalization: user.userImpersonalization,
      memberID: user.memberID,
      uid: user.uid,
      regionSelected: userRegion,
      eventCategory: 'interactions',
      eventAction: 'input',
      eventStatus: 'success',
      eventNonInteraction: '0',
      blockName: 'blockSearch',
    })
  }

  const onSearchResultSelectGtmEvent = (searchRequest: string, productId: string) => {
    pushGtmEvent({
      event: 'userEvent',
      userAuth: user.userAuth,
      userImpersonalization: user.userImpersonalization,
      memberID: user.memberID,
      uid: user.uid,
      regionSelected: userRegion,
      eventCategory: 'interactions',
      eventAction: 'click',
      eventStatus: 'success',
      eventNonInteraction: '0',
      blockName: 'blockSearch',
      eventLabel: searchRequest,
      eventContext: productId,
    })
  }

  const onSearchButtonClickGtmEvent = (searchRequest: string) => {
    pushGtmEvent({
      event: 'userEvent',
      userAuth: user.userAuth,
      userImpersonalization: user.userImpersonalization,
      memberID: user.memberID,
      uid: user.uid,
      regionSelected: userRegion,
      eventCategory: 'interactions',
      eventAction: 'click',
      eventStatus: 'success',
      eventNonInteraction: '0',
      blockName: 'blockSearch',
      eventLabel: searchRequest,
    })
  }

  const onSuggestionClickGtmEvent = (tagName: string) => {
    pushGtmEvent({
      event: 'userEvent',
      userAuth: user.userAuth,
      userImpersonalization: user.userImpersonalization,
      memberID: user.memberID,
      uid: user.uid,
      regionSelected: userRegion,
      eventCategory: 'interactions',
      eventAction: 'click',
      eventStatus: 'success',
      eventNonInteraction: '0',
      blockName: 'blockSearch',
      eventLabel: 'popular',
      eventContext: tagName,
    })
  }

  return { onSearchInputGtmEvent, onSearchResultSelectGtmEvent, onSearchButtonClickGtmEvent, onSuggestionClickGtmEvent }
}
