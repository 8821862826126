import React from 'react'
import type { IProductsCountProps } from './types'
import './styles.scss'

const ProductsCount = (props: IProductsCountProps) => {
  const { children } = props

  if (!children) return null

  return <h3 className="productsCount">{children}</h3>
}

export default ProductsCount
