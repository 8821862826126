import React from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { Button } from '@/components/ui'
import { EJournalRoute } from '@/types'
import { getMinioImagePath } from '@/utils/images'
import { createRouterLink } from '@/utils/url'
import { messages } from './messages'
import type { IPublicationBannerProps } from './types'
import './styles.scss'

const PublicationBanner = (props: IPublicationBannerProps) => {
  const { publication, onClick } = props

  return (
    <CommonLink
      url={createRouterLink(EJournalRoute.PUBLICATION_ITEM, { publicationSlug: publication.slug })}
      onClick={() => onClick?.(publication.slug)}
      className="indexBanner link-no-hover"
      style={{
        backgroundImage: `url(${getMinioImagePath({
          src: publication.coverImage?.rectangleCover || publication.coverImage?.squareCover,
        })})`,
      }}
    >
      <article className="indexBanner__content">
        <h2 className="font_base-sm text_body-sm h2 h3-md indexBanner__title">{publication.title}</h2>

        <div className="indexBanner__link">
          <Button onClick={onClick} className="color_black" modifiers={['white']}>
            {messages.buttonTitle}
          </Button>
        </div>
      </article>
    </CommonLink>
  )
}

export default PublicationBanner
