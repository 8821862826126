import type { FC } from 'react'
import React from 'react'
import type { IIconProps } from '@frontend/pole-ui/lib/components/Icon'
import { EIconName, EIconSize } from '@frontend/pole-ui/lib/components/Icon'
import { Button } from '@/components/ui'
import { messages } from './messages'
import type { TSearchInputWrapperProps } from './types'
import './styles.scss'

const resettingIconProps: IIconProps = { name: EIconName.Exit }

const SearchInputWrapper: FC<TSearchInputWrapperProps> = props => {
  const { withActions, onReset, children } = props

  return (
    <div className="inputsSearchWrapper">
      {children}

      <div className="inputsSearchWrapper__control">
        {withActions && (
          <Button isUnstyled onClick={onReset} iconProps={resettingIconProps} className="inputsSearchWrapper__control--reset" />
        )}
        <Button modifiers={['green']} className="inputsSearchWrapper__control--submit inputsSearchWrapper__button--desktop" type="submit">
          {messages.search}
        </Button>
        <Button
          iconPosition="right"
          iconProps={{ name: EIconName.Search, size: EIconSize.M }}
          className="inputsSearchWrapper__control--submit inputsSearchWrapper__button--mobile"
          type="submit"
        />
      </div>
    </div>
  )
}

export default SearchInputWrapper
