import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { useKeenSlider } from 'keen-slider/react'
import { useGetMainPagePublication } from '@/api/kubik/infoportal'
import { useGetSliderBanners } from '@/api/kubik/monolith'
import { PaginationDots, Skeleton, SwiperArrow } from '@/components/ui'
import { useAuthenticateUser } from '@/components/ui/AuthButton/hooks'
import { autoSwitchPlugin } from '@/components/ui/Slider/plugins'
import { useIndexBannerGtmEvent } from '@/logic/metrika/index/banners/gtmEvents'
import { getUserRegion } from '@/logic/userRegion'
import { PublicationBanner, SliderBanner } from '@/pages/IndexPage/components'
import type { RootState } from '@/redux/interfaces'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { MAIN_BANNER_SKELETONS } from './constants'
import './styles.scss'

const mapState = (state: RootState) => ({
  userRegion: getUserRegion()(state),
})

const IndexBanners = () => {
  const { userRegion } = useSelector(mapState)
  const { isMobile } = useDeviceType()
  const [activeSlide, setActiveSlide] = useState(0)
  const [totalSlides, setTotalSlides] = useState(0)
  const { isAuthenticated } = useAuthenticateUser()
  const { clickBannerGtmEvent, setRefCallback } = useIndexBannerGtmEvent('blockBannerMain')

  const {
    data: sliderBanners,
    isLoading: isBannersLoading,
    isError: isBannersError,
    isSuccess: isGetMainBannersSuccess,
  } = useGetSliderBanners({ regionCode: userRegion.code }, { query: { staleTime: Infinity } })

  const { data: mainPagePublication } = useGetMainPagePublication({ query: { staleTime: 1200000 } })

  const shouldHideBannerBlock = isBannersError || (isGetMainBannersSuccess && !sliderBanners.data?.banners.length && totalSlides < 1)

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: totalSlides > 1,

      slides: { perView: 'auto', spacing: 4 },
      selector: '.sliderBanner__slide',
      updated: slider => {
        if (slider.slides.length !== totalSlides) {
          setTotalSlides(slider.slides.length)
        }
      },

      slideChanged(slider) {
        const relativeIndex = slider.track.absToRel(slider.track.details.abs)

        setActiveSlide(relativeIndex)
      },
    },
    [autoSwitchPlugin],
  )

  const shouldShowSliderNavigation = totalSlides > 1

  useEffect(() => {
    if (sliderBanners?.data?.banners) {
      instanceRef.current?.update()
    }
  }, [sliderBanners, instanceRef, mainPagePublication, isAuthenticated])

  if (shouldHideBannerBlock) return null

  return (
    <section className="indexBanners page-wrapper">
      <Skeleton isLoading={isBannersLoading} skeletons={MAIN_BANNER_SKELETONS}>
        <div ref={sliderRef} className="keen-slider indexBanners__slider">
          {sliderBanners?.data?.banners.map((banner, index) => (
            <SliderBanner banner={banner} gtmSlideTracker={setRefCallback} index={index} key={banner.id} onClick={clickBannerGtmEvent} />
          ))}
          {sliderBanners?.data?.banners && mainPagePublication?.data?.id && (
            <div
              key={mainPagePublication.data.id}
              className="sliderBanner__slide"
              ref={node =>
                setRefCallback({
                  node,
                  index: (instanceRef.current?.slides.length || 1) - 1,
                  productId: String(mainPagePublication?.data?.id),
                })
              }
            >
              <PublicationBanner publication={mainPagePublication.data} />
            </div>
          )}
        </div>
        {shouldShowSliderNavigation && (
          <>
            <PaginationDots
              className="indexBanners__paginationDots"
              dotsQuantity={instanceRef.current?.slides.length || 0}
              activeDotNumber={activeSlide + 1}
              onClick={instanceRef.current?.moveToIdx}
              dotSize={isMobile ? 6 : 8}
              activeDotWidth={isMobile ? 16 : 24}
              activeDotStyle={EColor.WHITE}
            />

            <SwiperArrow
              modifier="swiper-with-white-background"
              direction="left"
              onClick={instanceRef.current?.prev}
              className="indexBanners__controlArrowItem indexBanners__controlArrowItem--left"
            />
            <SwiperArrow
              modifier="swiper-with-white-background"
              direction="right"
              onClick={instanceRef.current?.next}
              className="indexBanners__controlArrowItem indexBanners__controlArrowItem--right"
            />
          </>
        )}
      </Skeleton>
    </section>
  )
}

export default IndexBanners
