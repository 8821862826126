import { EAgriculturalProductsRoute, EFinancingOfferRoute, EHarvestSaleRoute } from '@/types'

export const getBannerTypeByUrl = (url: string) => {
  switch (url) {
    case EAgriculturalProductsRoute.main:
      return 'inputs'

    case EHarvestSaleRoute.main:
      return 'outputs'

    case EFinancingOfferRoute.main:
      return 'finance'

    default:
      return 'null'
  }
}
