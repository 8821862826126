import type { FC } from 'react'
import React, { memo } from 'react'
import { GetMainPageBannersQueryParamsType, useGetMainPageBanners } from '@/api/kubik/monolith'
import { useIndexBannerGtmEvent } from '@/logic/metrika/index/banners/gtmEvents'
import { PromoSliderBlock, SectionBlock, SectionHeading } from '@/pages/IndexPage/components'
import { messages } from './messages'
import type { IBestPromoOffersProps } from './types'
import './styles.scss'

const BestPromoOffers: FC<IBestPromoOffersProps> = props => {
  const { region } = props

  const {
    data: bannersData,
    isLoading,
    isSuccess,
    isError,
  } = useGetMainPageBanners(
    {
      regionCode: region.code,
      type: GetMainPageBannersQueryParamsType.INPUTS_PROMO,
    },
    { query: { staleTime: Infinity } },
  )

  const { clickBannerGtmEvent, setRefCallback } = useIndexBannerGtmEvent('blockBannerMain4')

  const shouldHideBlock = (isSuccess && !bannersData.data?.banners.length) || isError

  if (shouldHideBlock) return null

  return (
    <>
      <SectionBlock className="catalogPromo__headerWrapper">
        <SectionHeading header={messages.header} className="catalogPromo__header" />
      </SectionBlock>

      <SectionBlock>
        <PromoSliderBlock
          isLoading={isLoading}
          slideClassName="catalogPromo__slide"
          promoBanners={bannersData?.data?.banners}
          visibleItems={3}
          onBannerClickGtmEvent={clickBannerGtmEvent}
          onViewCardRefCallback={setRefCallback}
        />
      </SectionBlock>
    </>
  )
}

export default memo(BestPromoOffers)
