import type { FC } from 'react'
import React, { memo } from 'react'
import { usePartnersGtmEvents } from '@/logic/metrika/index/partners/gtmEvents'
import { useWebpExtension } from '@/utils/hooks/image'
import { PartnersSlider } from '../'

const OurPartners: FC = () => {
  const imageExtension = useWebpExtension()

  const { onClickPartnerGtmEvent } = usePartnersGtmEvents()

  return <PartnersSlider imageExtension={imageExtension} onItemClick={onClickPartnerGtmEvent} />
}

export default memo(OurPartners)
