import React from 'react'
import { GetMainPageBannersQueryParamsType, useGetMainPageBanners } from '@/api/kubik/monolith'
import { PromoSliderBlock } from '@/pages/IndexPage/components'
import type { ILoanBannerProps } from './types'
import './styles.scss'

const LoanBanner = (props: ILoanBannerProps) => {
  const { region, className } = props

  const {
    data: bannerData,
    isLoading,
    isSuccess,
    isError,
  } = useGetMainPageBanners({ regionCode: region?.code, type: GetMainPageBannersQueryParamsType.FINANCE })

  const shouldHideBlock = (isSuccess && !bannerData.data?.banners.length) || isError

  if (shouldHideBlock) return null

  return (
    <PromoSliderBlock
      isLoading={isLoading}
      className={className}
      slideClassName="loanBanner__slide"
      promoBanners={bannerData?.data?.banners}
      visibleItems={1}
    />
  )
}

export default LoanBanner
