import type { FC } from 'react'
import React, { memo } from 'react'
import { useGetAboutBanner } from '@/api/kubik/monolith'
import { Icon } from '@/components/ui'
import { useAboutUsGtmEvent } from '@/logic/metrika/index/aboutUs/gtmEvents'
import { IndexPageSection, SectionBlock, SectionHeading } from '@/pages/IndexPage/components/'
import { ADVANTAGES_ICONS } from '@/pages/IndexPage/constants'
import { ServicesCard } from './components'
import { getBannerTypeByUrl } from './helpers'
import './styles.scss'

const AboutMarketplace: FC = () => {
  const { data: aboutBanner } = useGetAboutBanner({ query: { staleTime: Infinity } })

  const { onAboutUsCardClickGtmEvent } = useAboutUsGtmEvent()

  if (!aboutBanner?.data) {
    return null
  }

  const onCardClick = (url: string) => {
    const route = getBannerTypeByUrl(url)
    onAboutUsCardClickGtmEvent(route)
  }

  return (
    <IndexPageSection className="aboutMarketplace">
      <SectionBlock>
        <div className="aboutMarketplace__header">
          <SectionHeading className="aboutMarketplace__title" header={aboutBanner.data.title} />
          <ul className="aboutMarketplace__advantages">
            {aboutBanner.data.buttons.map((btn, index) => (
              <li className="aboutMarketplace__advantageItem" key={`${btn.text}-${btn.url}`}>
                <Icon name={ADVANTAGES_ICONS[index] || ADVANTAGES_ICONS[0]} />
                <p>{btn.text}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className="aboutMarketplace__services">
          {aboutBanner.data.banners.map((banner, index) => (
            <ServicesCard
              key={index}
              header={banner.title}
              subHeader={banner.text}
              url={banner.url}
              image={banner.image}
              onCardClick={() => onCardClick(banner.url)}
            />
          ))}
        </div>
      </SectionBlock>
    </IndexPageSection>
  )
}

export default memo(AboutMarketplace)
