import type { FC } from 'react'
import React, { memo } from 'react'
import { formatHighlightedText } from '@/components/ui/HighlightedText'
import { Badge } from '../../..'
import { messages } from '../../messages'
import type { TConsultingHeaderProps } from './types'
import './styles.scss'

const ConsultingHeader: FC<TConsultingHeaderProps> = ({ onClick }) => {
  return (
    <div className="consultingHeader">
      <Badge className="text_super-small-sm consultingHeader__badge" text={messages.header.badge} />
      <h1 className="h2 h3-only-md consultingHeader__title" onClick={onClick}>
        {formatHighlightedText(messages.header.title)}
      </h1>
    </div>
  )
}

export default memo(ConsultingHeader)
