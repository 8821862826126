import type { FC } from 'react'
import React from 'react'
import { MobileAuthButton } from '@/components/composed'
import { AboutMarketplace, BestOffers, Ifa, IndexBanners, Inputs, Outputs, Partners } from '@/pages/IndexPage/sections'

const IndexPage: FC = () => {
  return (
    <section className="indexPage">
      <MobileAuthButton visibilityMinOffset={1200} />
      <IndexBanners />
      <AboutMarketplace />
      <BestOffers />
      <Inputs />
      <Outputs />
      <Ifa />
      <Partners />
    </section>
  )
}
export default IndexPage
