import React from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import { EAgriculturalProductsRoute } from '@/types'
import { createRouterLink } from '@/utils/url'
import { messages } from './messages'
import type { IAllItemsButton } from './types'
import './styles.scss'

const AllItemsButton = <T extends { id?: string | number; name?: string }>(props: IAllItemsButton<T>) => {
  const { category, className, onClick } = props

  if (!category || !category.name || !category.id) return null

  const { id, name } = category
  const lastNameChar = name[name.length - 1]

  return (
    <CommonLink
      url={createRouterLink(EAgriculturalProductsRoute.category, { categoryId: id })}
      onClick={onClick}
      className={classNames('button_outline button_small button allItemsButton', className)}
    >
      {`${lastNameChar !== 'а' ? messages.masculine : messages.feminine} ${name.toLowerCase()}`}
    </CommonLink>
  )
}

export default AllItemsButton
