import React from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { useFinancingFormInteractGtmEvents } from '@/logic/metrika/index/financingForm/gtmEvents'
import { EFinancingOfferRoute } from '@/types'
import { createRouterLink } from '@/utils/url'
import { messages } from './messages'

const AllItemsButton = () => {
  const { onAllFinancingProductsClickGtmEvent } = useFinancingFormInteractGtmEvents()

  return (
    <CommonLink
      url={createRouterLink(EFinancingOfferRoute.experimentalMain)}
      onClick={onAllFinancingProductsClickGtmEvent}
      className="button_outline button_small button"
    >
      {messages.allServices}
    </CommonLink>
  )
}

export default AllItemsButton
