import type { TMessages } from '@/types/TMessages'

export const messages = {
  header: {
    machinery: 'Техника и запчасти',
    agroproducts: 'Растениеводство и животноводство',
  },
  subheader: {
    machinery: 'Приобретайте товары в рассрочку и лизинг',
    agroproducts: 'Покупайте товары в рассрочку и с постоплатой',
  },
  placeholder: {
    machinery: 'Поиск по технике и запчастям',
    agroproducts: 'Поиск по семенам, препаратам и кормовым добавкам',
  },
} satisfies TMessages
