export const CROPS_IDS = [
  'barley',
  'chickpeas',
  'corn',
  'lentils',
  'mustard',
  'oilseedFlax',
  'oilseedSunflower',
  'peas',
  'rapeseedPlant',
  'soybean',
  'soybeanExtruded',
  'soybeanMeal',
  'soybeanRawOil',
  'sunflowerCake',
  'sunflowerConfectionery',
  'sunflowerMeal',
  'sunflowerOil',
  'wheat',
  'wheatDurum',
] as const
