import React from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import type { MainPageBannerData } from '@/api/kubik/monolith'
import { GetMainPageBannersQueryParamsType, MainPageBannerDataSpecialAction, useGetMainPageBanners } from '@/api/kubik/monolith'
import { Image, Skeleton } from '@/components/ui'
import { useAuthenticateUser } from '@/components/ui/AuthButton/hooks'
import { MATCH_MEDIA_QUERIES } from '@/constants/common'
import { getMinioImagePath } from '@/utils/images'
import { skeletons } from './constants'
import type { IBecomePartnersBannersProps } from './types'
import './styles.scss'

const BecomePartners = (props: IBecomePartnersBannersProps) => {
  const { region } = props
  const { openAuthModal, isAuthenticated } = useAuthenticateUser()

  const {
    data: bannerData,
    isLoading,
    isSuccess,
    isError,
  } = useGetMainPageBanners({ regionCode: region?.code, type: GetMainPageBannersQueryParamsType.PARTNERS })

  const onBannerClick = (banner: MainPageBannerData) => {
    if (banner.specialAction === MainPageBannerDataSpecialAction.REGISTRATION_FORM && !isAuthenticated) openAuthModal()
  }

  const shouldHideBlock = (isSuccess && !bannerData.data?.banners.length) || isError

  if (shouldHideBlock) return null

  return (
    <div className="becomePartners">
      <Skeleton isLoading={isLoading} skeletons={skeletons}>
        {bannerData?.data?.banners.map((banner, index) => (
          <CommonLink
            key={index}
            spanIfEmpty
            url={banner.specialAction === MainPageBannerDataSpecialAction.URL ? banner.url : undefined}
            onClick={() => onBannerClick(banner)}
          >
            <picture>
              <source srcSet={getMinioImagePath({ src: banner.mobileImage })} media={MATCH_MEDIA_QUERIES.mobile} />
              <Image src={banner.webImage} className="becomePartners__slide" />
            </picture>
          </CommonLink>
        ))}
      </Skeleton>
    </div>
  )
}

export default BecomePartners
