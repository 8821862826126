import loadable from '@loadable/component'

export { default as Badge } from './Badge'
export { ConsultingForm } from './FinancingConsulting/components'

export const FinancingConsulting = loadable(
  /* webpackChunkName: "FinancingConsulting" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./FinancingConsulting'),
)
