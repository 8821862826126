import { formatLocalizedString } from '@/utils/formatUtils'
import { messages } from './messages'
import type { IGetOffersNumberProps } from './types'

export const getOffersNumber = (props: IGetOffersNumberProps) => {
  const { type, offers } = props

  if (!offers) return undefined

  const formattedNumber = new Intl.NumberFormat('ru').format(offers)

  return formatLocalizedString(messages[type], { formattedNumber, number: offers })
}
