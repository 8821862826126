import { isInputsProduct } from '@/typeguards/index/isInputsProduct'
import { translit } from '@/utils/helpers'
import type { IEcommerceInputsItemProps, IEcommerceOutputsItemProps, IGtmProductDataProps } from './types'

const prepareName = (name: string) => translit(name).toLowerCase().replace(/\s+/g, '')

export const createProductGtmDataObject = (props: IGtmProductDataProps) => {
  const { index, product, blockName } = props

  if (isInputsProduct(product)) {
    const productOffer = product.offers?.[0]

    return {
      item_name: prepareName(product.name),
      currency: productOffer?.price?.uom || null,
      discount: productOffer?.discountPercent ? String(productOffer?.discountPercent) : null,
      index,
      item_brand: product.producer?.name ? prepareName(product.producer.name) : null,
      item_category: String(product.category.id),
      item_id: product.id,
      item_list_name: blockName,
      item_tags: null,
      price: productOffer?.price?.value || 0,
      quantity: 1,
      promotion_id: null,
    } satisfies IEcommerceInputsItemProps
  }

  return {
    item_id: product.id,
    item_buyer: product.buyer.id,
    item_category: product.culture.id,
    item_list_name: 'mainBlock',
    index,
  } satisfies IEcommerceOutputsItemProps
}
