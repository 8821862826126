import React, { memo } from 'react'
import { useNewsGtmEvents } from '@/logic/metrika/index/news/gtmEvents'
import { NewsCard, SectionBlock, SectionHeading } from '@/pages/IndexPage/components'
import { useNewsApi } from './hooks'
import { messages } from './messages'
import type { INewsProps } from './types'
import './styles.scss'

const News = (props: INewsProps) => {
  const { type } = props
  const { isError, isSuccess, news } = useNewsApi(type)

  const { onClickNewsItemGtmEvent } = useNewsGtmEvents()

  const shouldHideNews = isError || (isSuccess && news?.data?.publications && news.data.publications.length < 2)

  if (shouldHideNews) return null

  return news?.data ? (
    <SectionBlock className="newsSection">
      <SectionHeading header={messages.header} />
      <div className="newsSection__list">
        {news.data.publications.map(element => (
          <NewsCard key={element.id} news={element} onClick={() => onClickNewsItemGtmEvent(String(element.id))} />
        ))}
      </div>
    </SectionBlock>
  ) : null
}

export default memo(News)
