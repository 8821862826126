import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { getUserGtmInfo, pushGtmEvent } from '@/logic/metrika/helpers'
import { useSetElementsRef } from '@/logic/metrika/index/helpers'
import { getUserRegion } from '@/logic/userRegion'
import type { RootState } from '@/redux/interfaces'
import { isInputsProduct } from '@/typeguards/index/isInputsProduct'
import { useObserveElements } from '@/utils/hooks/index/useObserveElements'
import { createProductGtmDataObject } from './helpers'
import type { ICardClickEventProps, IProductCardProps, IViewProductsCardProps, TEcommerceItem } from './types'

const mapState = (state: RootState) => ({
  user: getUserGtmInfo()(state),
  userRegion: getUserRegion()(state),
})

export const useOnProductCardsViewGtmEvent = (props: IViewProductsCardProps) => {
  const { user, userRegion } = useSelector(mapState)
  const { products, blockName } = props

  const isInputsItem = !!products && !!products[0] && isInputsProduct(products[0])

  const { attachedRefId, observableElements, setRefCallback } = useSetElementsRef()

  const viewedProductsIds = useObserveElements({ observableElements, attachedRefId })

  const userViewedProducts = useRef<Record<string, TEcommerceItem>>({})

  const prepareEventContext = () => {
    if (!viewedProductsIds.length) return ''
    const modifier = isInputsItem ? '&lst:mainBlock' : ''

    return `prd:${viewedProductsIds.join(',')}${modifier}`
  }

  useEffect(() => {
    if (!viewedProductsIds.length || !products || !products.length) return

    let productIndex = 0

    const currentCategoryViewedProductsData = viewedProductsIds.reduce<Record<string, TEcommerceItem>>((result, currentProductId) => {
      if (result[currentProductId]) return result

      const currentProduct = products.find(product => product.id === currentProductId)

      if (!currentProduct) return result

      productIndex += 1

      result[currentProductId] = createProductGtmDataObject({ index: productIndex, product: currentProduct, blockName })

      return result
    }, {})

    userViewedProducts.current = { ...userViewedProducts.current, ...currentCategoryViewedProductsData }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewedProductsIds])

  useEffect(() => {
    return () => {
      const productData = Object.values(userViewedProducts.current)
      if (!productData.length) return

      pushGtmEvent({
        event: 'userEvent',
        userAuth: user.userAuth,
        userImpersonalization: user.userImpersonalization,
        memberID: user.memberID,
        uid: user.uid,
        regionSelected: userRegion,
        eventCategory: 'interactions',
        eventAction: 'viewing',
        eventStatus: 'success',
        eventNonInteraction: '0',
        blockName: isInputsItem ? 'blockListingInputs' : 'blockListingOutputs',
        eventLabel: isInputsItem ? 'productInputs' : 'lotsOutputs',
        eventContext: prepareEventContext(),
        eventEcommerce: 'view_item_list',
        ecommerce: { items: productData },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return setRefCallback
}

export const useProductCardInteractGtmEvents = (props: IProductCardProps) => {
  const { user, userRegion } = useSelector(mapState)
  const { blockName } = props

  const onProductCardClickGtmEvent = (eventProps: ICardClickEventProps) => {
    const { index, actionNotPermitted, product } = eventProps

    const isInputsItem = !!product && isInputsProduct(product)

    const productData = createProductGtmDataObject({ index, product, blockName })

    pushGtmEvent({
      event: 'userEvent',
      userAuth: user.userAuth,
      userImpersonalization: user.userImpersonalization,
      memberID: user.memberID,
      uid: user.uid,
      regionSelected: userRegion,
      eventCategory: 'interactions',
      eventAction: 'click',
      eventStatus: actionNotPermitted ? 'unsuccess' : 'success',
      eventNonInteraction: '0',
      blockName: isInputsItem ? 'blockListingInputs' : 'blockListingOutputs',
      eventLabel: isInputsItem ? 'productInputs' : 'lotsOutputs',
      eventContext: `prd:${product.id}${isInputsItem ? '&lst:mainBlock' : ''}`,
      eventEcommerce: 'select_item',
      ecommerce: productData,
    })
  }

  const onClickGetAllPricesGtmEvent = (eventProps: ICardClickEventProps) => {
    const { index, product } = eventProps
    const productData = createProductGtmDataObject({ index, product, blockName })

    pushGtmEvent({
      event: 'userEvent',
      userAuth: user.userAuth,
      userImpersonalization: user.userImpersonalization,
      memberID: user.memberID,
      uid: user.uid,
      regionSelected: userRegion,
      eventCategory: 'interactions',
      eventAction: 'click',
      eventStatus: 'success',
      eventNonInteraction: '0',
      blockName: 'blockListingInputs',
      eventLabel: 'buttonMultiRequest',
      eventContext: `prd:${product.id}`,
      eventEcommerce: 'add_to_cart',
      ecommerce: productData,
    })
  }

  const onClickOutputsOfferReactGtmEvent = (eventProps: ICardClickEventProps) => {
    const { index, product } = eventProps
    const productData = createProductGtmDataObject({ index, product, blockName })

    pushGtmEvent({
      event: 'userEvent',
      userAuth: user.userAuth,
      userImpersonalization: user.userImpersonalization,
      memberID: user.memberID,
      uid: user.uid,
      regionSelected: userRegion,
      eventCategory: 'interactions',
      eventAction: 'click',
      eventStatus: 'success',
      eventNonInteraction: '0',
      blockName: 'blockListingInputs',
      eventLabel: 'lotsOutputs',
      eventContext: `prd:${product.id}`,
      eventEcommerce: 'add_to_cart',
      ecommerce: productData,
    })
  }

  return {
    inputsGtmEvents: { onProductCardClickGtmEvent, onClickGetAllPricesGtmEvent },
    outputsGtmEvents: {
      onClickOutputsOfferReactGtmEvent,
    },
  }
}
