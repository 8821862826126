import React, { memo, useRef } from 'react'
import type { ITextInputProps } from '@frontend/pole-ui/lib/components/TextInput'
import { AgriculturalProductCatalogSearchInput } from '@/components/composed/AgriculturalProductCatalogSearchInput'
import { useIndexSearchGtmEvent } from '@/logic/metrika/index/search/gtmEvents'
import { useCatalogSearch } from '@/pages/IndexPage/hooks/useCatalogSearch'
import type { TMarketSearchSuggestion } from '@/services/MarketSearchService/interfaces'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { SearchInputWrapper } from './components/'
import type { ISearchBarProps } from './types'
import './styles.scss'

const InputsSearchBar = (props: ISearchBarProps) => {
  const { placeholder } = props
  const { onSearch } = useCatalogSearch()
  const { isMobile } = useDeviceType()

  const searchString = useRef<string>('')

  const { onSearchButtonClickGtmEvent, onSearchInputGtmEvent, onSearchResultSelectGtmEvent } = useIndexSearchGtmEvent()

  const onSearchInput = (newValue: Maybe<string>) => {
    if (newValue) {
      onSearchButtonClickGtmEvent(newValue)
      onSearch(newValue)
    }
  }

  const onSuggestionChange = (suggestion: Maybe<TMarketSearchSuggestion>) => {
    if (!suggestion) return
    onSearchResultSelectGtmEvent(searchString.current, suggestion.id)
  }

  const onChange = (inputValue: string) => {
    searchString.current = inputValue
    onSearchInputGtmEvent()
  }

  const mobileModifiers: ITextInputProps['modifiers'] = ['outline-none', 'small', 'white']
  const fullModifiers: ITextInputProps['modifiers'] = ['white']

  const customInputProps: Partial<ITextInputProps> = {
    placeholder,
    type: 'text',
    modifiers: isMobile ? mobileModifiers : fullModifiers,
  }

  return (
    <AgriculturalProductCatalogSearchInput
      className="inputsSearch"
      customInputProps={customInputProps}
      shouldOpenInModal={isMobile}
      customInputWrapper={SearchInputWrapper}
      onInput={onSearchInput}
      onChange={onChange}
      onSuggestChange={onSuggestionChange}
    />
  )
}

export default memo(InputsSearchBar)
