import type { FC } from 'react'
import React, { memo } from 'react'
import Swiper from 'react-id-swiper/lib/ReactIdSwiper'
import type { ReactIdSwiperProps } from 'react-id-swiper/lib/types'
import classNames from 'classnames'
import { Image, SwiperArrow } from '@/components/ui'
import { useSwiper } from '@/components/ui/SwiperArrows/useSwiper'
import { useSwiperArrows } from '@/utils/hooks/index/useSwiperArrows'
import { PARTNERS } from './constants'
import type { IPartnersSliderProps } from './types'
import './styles.scss'

const PartnersSlider: FC<IPartnersSliderProps> = props => {
  const { className, imageExtension, onItemClick } = props

  const { swiperRef, slidePrevious, slideNext } = useSwiper()
  const { arrows, swiperArrowParams } = useSwiperArrows()
  const { isBackArrowVisible, isForwardArrowVisible } = arrows

  const swiperParams: ReactIdSwiperProps = {
    slidesPerView: 'auto',
    spaceBetween: 30,
    slidesPerGroup: 1,
    breakpoints: {
      1000: {
        slidesPerGroup: 5,
      },
      768: {
        slidesPerGroup: 3,
      },
      0: {
        slidesPerView: 'auto',
      },
    },
    on: {
      ...swiperArrowParams,
    },
  }

  return (
    <div className={classNames('partnersSlider', className)}>
      <Swiper ref={swiperRef} {...swiperParams}>
        {PARTNERS.map(({ name, height }) => (
          <div key={name} className="partnersSlider__item" onClick={() => onItemClick?.(name)}>
            <Image src={`index/partners/${name}.${imageExtension}`} isInStatic style={{ height: height.desktop }} />
          </div>
        ))}
      </Swiper>
      {isBackArrowVisible && (
        <SwiperArrow
          modifier="swiper-with-white-background"
          direction="left"
          onClick={slidePrevious}
          className="partnersSlider__controlArrowItem partnersSlider__controlArrowItem--left"
        />
      )}
      {isForwardArrowVisible && (
        <SwiperArrow
          modifier="swiper-with-white-background"
          direction="right"
          onClick={slideNext}
          className="partnersSlider__controlArrowItem partnersSlider__controlArrowItem--right"
        />
      )}
    </div>
  )
}

export default memo(PartnersSlider)
