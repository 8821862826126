import type { FC } from 'react'
import React, { memo } from 'react'
import { InputsCard } from '@/components/composed'
import { OutputsCard } from '@/pages/IndexPage/components'
import { isInputsProduct } from '@/typeguards/index/isInputsProduct'
import { isOutputsOffer } from '@/typeguards/index/isOutputsOffer'
import type { IUniversalItemCardProps } from './types'

const UniversalItemCard: FC<IUniversalItemCardProps> = itemCardProps => {
  const { index, product, cardOptions, gtmEvents } = itemCardProps
  const { onCardClickGtmEvent, onClickOutputsOfferReactGtmEvent } = gtmEvents

  if (isInputsProduct(product)) return <InputsCard item={product} onCardClickGtmEvent={() => onCardClickGtmEvent?.({ index, product })} />

  if (isOutputsOffer(product))
    return (
      <OutputsCard
        item={product}
        options={cardOptions?.outputs}
        onClickOutputsOfferReactGtmEvent={isActionPermitted =>
          onClickOutputsOfferReactGtmEvent?.({ index, product, actionNotPermitted: !isActionPermitted })
        }
      />
    )

  return null
}

export default memo(UniversalItemCard)
