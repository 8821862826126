// // TODO: сделать универсальный компонент из CultureSaleLots/CultureSaleRequestForm

import type { FC } from 'react'
import React, { useMemo, useRef, useState } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { FormProvider, useForm } from 'react-hook-form'
import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { useGetCultureMarketInfo } from '@/api/kubik/monolith'
import { addModalToWellKnown } from '@/components/composed/Modal/constants'
import { Button } from '@/components/ui'
import { useSaleRequestGtmEvents } from '@/logic/metrika/index/saleRequest/gtmEvents'
import { NoBuyersModal } from '@/pages/CulturesSaleLots/components'
import { EDeliveryBasisVariantId } from '@/types/HarvestSaleLots'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { CultureSaleForm, PricePredictionWidget } from './components'
import { PROTEIN_MAX_VALUE, PROTEIN_MIN_VALUE } from './consts'
import { validateProteinPercentage } from './helpers'
import { useSaleRequestSubmit } from './hooks'
import type { ISaleRequestFormData, ISaleRequestFormProps } from './types'
import './styles.scss'

addModalToWellKnown({ NoBuyersModal })

const SaleRequestForm: FC<ISaleRequestFormProps> = props => {
  const { culture } = props
  const { isDesktop } = useDeviceType()
  const [isCardExpanded, setIsCardExpanded] = useState(isDesktop)
  const shouldHideForm = !isDesktop && !isCardExpanded
  const ref = useRef<HTMLDivElement>(null)

  const formMethods = useForm<ISaleRequestFormData>({
    defaultValues: { deliveryBasis: EDeliveryBasisVariantId.pickup },
  })

  const { onClickCreateRequestGtmEvent } = useSaleRequestGtmEvents()
  const { onSaleRequestSubmit } = useSaleRequestSubmit()

  const onSubmit: SubmitHandler<ISaleRequestFormData> = data => {
    onSaleRequestSubmit(data, culture.id)
    onClickCreateRequestGtmEvent()
  }

  const [deliveryBasis, elevatorRegion, supplierStoreId, proteinFrom] = formMethods.getValues([
    'deliveryBasis',
    'elevatorRegion',
    'supplierStoreId',
    'proteinFrom',
  ])

  const protein = useMemo(
    () => (validateProteinPercentage(proteinFrom, PROTEIN_MIN_VALUE, PROTEIN_MAX_VALUE) ? Number(proteinFrom) : undefined),
    [proteinFrom],
  )
  const regionId = deliveryBasis === EDeliveryBasisVariantId.pickup ? undefined : elevatorRegion || undefined
  const storeId = deliveryBasis === EDeliveryBasisVariantId.pickup ? supplierStoreId : undefined

  const { data: buyersAndPrices } = useGetCultureMarketInfo(
    culture.id,
    {
      proteinPercentage: protein,
      regionId: Number(regionId) || undefined,
      storeId,
    },
    { query: { staleTime: Infinity, keepPreviousData: true } },
  )

  const toggleExpandCard = () => {
    if (!ref.current) return

    setIsCardExpanded(p => !p)

    ref.current?.classList.toggle('indexSaleRequestForm__hidden')

    requestAnimationFrame(() => {
      ref.current?.classList.toggle('indexSaleRequestForm__inputs--expanded')
    })
  }

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)} className="indexSaleRequestForm">
      <FormProvider {...formMethods}>
        <div ref={ref} className={classNames('indexSaleRequestForm__inputs', { indexSaleRequestForm__hidden: shouldHideForm })}>
          <CultureSaleForm buyersAndPrices={buyersAndPrices} culture={culture} />
        </div>
        <PricePredictionWidget buyersAndPrices={buyersAndPrices?.data} culture={culture} />

        {shouldHideForm && (
          <Button
            modifiers={['green', 'small', 'full-width-mobile']}
            iconPosition="right"
            iconProps={{ name: EIconName.ShevronRight }}
            className="requestFormWrapper__footerSubmitButton"
            onClick={toggleExpandCard}
          >
            Создать заявку
          </Button>
        )}
      </FormProvider>
    </form>
  )
}

export default SaleRequestForm
