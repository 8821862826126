import type { FC } from 'react'
import React, { memo } from 'react'
import classNames from 'classnames'
import { Skeleton, SwiperArrow } from '@/components/ui'
import { UniversalItemCard } from '@/pages/IndexPage/components/CardSliderBlock/components'
import { useSlider } from '@/utils/hooks/keenSlider/useSlider'
import { skeletons } from './constants'
import type { ICardSliderBlockProps } from './types'
import './styles.scss'

const CardSliderBlock: FC<ICardSliderBlockProps> = props => {
  const { isLoading, products, className, gtmEvents, cardOptions } = props
  const { onViewCardRefCallback, ...otherGtmEvents } = gtmEvents

  const { control, productsSliderRef } = useSlider({
    adaptiveSlideOptions: { isDesktop: 4, isTablet: 2, isMobile: 1 },
    slides: products,
    keenSliderOptions: { slides: { perView: 'auto', spacing: 16 }, selector: '.productsCatalogSlider__productCard' },
  })

  const { onNextClick, onPrevClick, showBackArrow, showForwardArrow } = control

  return (
    <div className="productsCatalogSlider">
      <Skeleton isLoading={isLoading} skeletons={skeletons}>
        <div className="productsCatalogSlider__container">
          <div ref={productsSliderRef} className="keen-slider productsCatalogSlider__slider">
            {products?.map((product, index) => (
              <div
                key={product.id}
                ref={node => onViewCardRefCallback({ node, index, productId: product.id })}
                className={classNames('productsCatalogSlider__productCard', className)}
              >
                <UniversalItemCard gtmEvents={otherGtmEvents} index={index} product={product} cardOptions={cardOptions} />
              </div>
            ))}
          </div>
        </div>
        {showBackArrow && (
          <SwiperArrow
            modifier="swiper-with-white-background"
            direction="left"
            onClick={onPrevClick}
            className="productsCatalogSlider__controlArrowItem productsCatalogSlider__controlArrowItem-left"
          />
        )}

        {showForwardArrow && (
          <SwiperArrow
            modifier="swiper-with-white-background"
            direction="right"
            onClick={onNextClick}
            className="productsCatalogSlider__controlArrowItem productsCatalogSlider__controlArrowItem-right"
          />
        )}
      </Skeleton>
    </div>
  )
}

export default memo(CardSliderBlock)
