import { useSelector } from 'react-redux'
import { getUserGtmInfo, pushGtmEvent } from '@/logic/metrika/helpers'
import type { IFormInputGtmEventProps } from '@/logic/metrika/index/financingForm/types'
import { useSetElementsRef } from '@/logic/metrika/index/helpers'
import { getUserRegion } from '@/logic/userRegion'
import type { RootState } from '@/redux/interfaces'
import { useObserveElements } from '@/utils/hooks/index/useObserveElements'

const mapState = (state: RootState) => ({
  user: getUserGtmInfo()(state),
  userRegion: getUserRegion()(state),
})

export const useFinancingFormViewGtmEvent = () => {
  const { user, userRegion } = useSelector(mapState)

  const onViewFormSendGtmEvent = () => {
    pushGtmEvent({
      event: 'userEvent',
      userAuth: user.userAuth,
      userImpersonalization: user.userImpersonalization,
      memberID: user.memberID,
      uid: user.uid,
      regionSelected: userRegion,
      eventCategory: 'interactions',
      eventAction: 'viewing',
      eventStatus: 'success',
      eventNonInteraction: '0',
      blockName: 'blockLoanApplicationForm',
      fundingProduct: 'MARKETPLACE',
    })
  }

  const { attachedRefId, observableElements, setRefCallback } = useSetElementsRef()

  useObserveElements({ observableElements, attachedRefId, onIntersect: onViewFormSendGtmEvent, shouldSubmitOnEachView: true })

  return { setRefCallback }
}

export const useFinancingFormInteractGtmEvents = () => {
  const { user, userRegion } = useSelector(mapState)

  const onFormFieldInputGtmEvent = (props: IFormInputGtmEventProps) => {
    const { eventLabel } = props

    pushGtmEvent({
      event: 'userEvent',
      userAuth: user.userAuth,
      userImpersonalization: user.userImpersonalization,
      memberID: user.memberID,
      uid: user.uid,
      regionSelected: userRegion,
      eventCategory: 'interactions',
      eventAction: 'input',
      eventStatus: 'success',
      eventNonInteraction: '0',
      blockName: 'blockLoanApplicationForm',
      eventLabel,
      fundingProduct: 'MARKETPLACE',
    })
  }

  const onAuthOnFormGtmEvent = () => {
    pushGtmEvent({
      event: 'userEvent',
      userAuth: user.userAuth,
      userImpersonalization: user.userImpersonalization,
      memberID: user.memberID,
      uid: user.uid,
      regionSelected: userRegion,
      eventCategory: 'interactions',
      eventAction: 'backgroundProcess',
      eventStatus: 'success',
      eventNonInteraction: '0',
      blockName: 'blockLoanApplicationForm',
      eventLabel: 'step_to-authorization',
      fundingProduct: 'MARKETPLACE',
    })
  }

  const onFormSubmitGtmEvent = (requestID: string) => {
    pushGtmEvent({
      event: 'userEvent',
      userAuth: user.userAuth,
      userImpersonalization: user.userImpersonalization,
      memberID: user.memberID,
      uid: user.uid,
      regionSelected: userRegion,
      eventCategory: 'interactions',
      eventAction: 'formSend',
      eventStatus: 'success',
      eventNonInteraction: '0',
      blockName: 'blockLoanApplicationForm',
      requestID,
      fundingProduct: 'MARKETPLACE',
    })
  }

  const onAllFinancingProductsClickGtmEvent = () => {
    pushGtmEvent({
      event: 'userEvent',
      userAuth: user.userAuth,
      userImpersonalization: user.userImpersonalization,
      memberID: user.memberID,
      uid: user.uid,
      regionSelected: userRegion,
      eventCategory: 'interactions',
      eventAction: 'click',
      eventStatus: 'success',
      eventNonInteraction: '0',
      blockName: 'blockFinance',
      eventLabel: 'all',
    })
  }

  return { onFormFieldInputGtmEvent, onAuthOnFormGtmEvent, onFormSubmitGtmEvent, onAllFinancingProductsClickGtmEvent }
}
