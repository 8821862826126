import type { FC } from 'react'
import React from 'react'
import { Button } from '@/components/ui'
import { useCatalogSearch } from '@/pages/IndexPage/hooks/useCatalogSearch'
import { POPULAR_QUERIES } from './constants'
import { messages } from './messages'
import type { ISearchInputPopularQueriesProps } from './types'
import './styles.scss'

const SearchInputPopularQueries: FC<ISearchInputPopularQueriesProps> = props => {
  const { type } = props
  const { onSearch } = useCatalogSearch()

  const onQuerySelect = (query: string) => {
    onSearch(query)
  }

  return (
    <div className="popularQueries">
      <span className="popularQueries__label text_small">{messages.label}</span>

      {POPULAR_QUERIES[type].map(query => (
        <Button className="popularQueries__queryButton text_small" isUnstyled key={query} onClick={() => onQuerySelect(query)}>
          {query}
        </Button>
      ))}
    </div>
  )
}

export default SearchInputPopularQueries
