import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getMainPageSaleTemplatesCulturesInfo, getMainPageSaleTemplatesCulturesInfoQueryKey } from '@/api/kubik/monolith'

export const useOutputsCategories = () => {
  const [category, setCategory] = useState(0)

  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: getMainPageSaleTemplatesCulturesInfoQueryKey(),
    queryFn: getMainPageSaleTemplatesCulturesInfo,
    staleTime: Infinity,
    select: response => {
      const sortedCategories = response?.data?.cultures.sort((a, b) => (a.position || 0) - (b.position || 0))

      return { categories: sortedCategories, productsCount: response?.data?.countByGroup, total: response.data?.total }
    },
  })

  return {
    outputsCategories: data?.categories,
    totalProducts: data?.productsCount,
    isLoading,
    isSuccess,
    isError,
    selectCategory: setCategory,
    catogoryIndex: category,
    currentCategory: data?.categories?.[category],
  }
}
