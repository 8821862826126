import React from 'react'
import { useGetMenuItemsList } from '@/api/kubik/monolith'
import { Skeleton } from '@/components/ui'
import { useFinancingGtmEvents } from '@/logic/metrika/index/financing/gtmEvents'
import { LoanCard } from '../LoanCard/LoanCard'
import { LOAN_CARD_SKELETONS } from './constants'
import './styles.scss'

const LoanList = () => {
  const { data: menuItems, isLoading, isSuccess } = useGetMenuItemsList({ query: { staleTime: Infinity } })

  const { onProductCardClickGtmEvent } = useFinancingGtmEvents()
  const shouldHideBlock = isSuccess && !menuItems.data

  if (shouldHideBlock) return null

  return (
    <div className="loanCardWrapper">
      <Skeleton skeletons={LOAN_CARD_SKELETONS} isLoading={isLoading}>
        {menuItems?.data?.menuItems.map((item, index) => (
          <LoanCard key={index} loan={item} image={item.image} onClick={() => onProductCardClickGtmEvent(item.url)} />
        ))}
      </Skeleton>
    </div>
  )
}

export default LoanList
