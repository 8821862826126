import type { FC } from 'react'
import React, { memo } from 'react'
import { useIndexBannerGtmEvent } from '@/logic/metrika/index/banners/gtmEvents'
import { PromoSliderBlock, SectionBlock } from '@/pages/IndexPage/components'
import type { IPromoBannerProps } from '../types'
import { useInputsPromoBannerApi } from './hooks'
import './styles.scss'

const ProductsPromoBanner: FC<IPromoBannerProps> = props => {
  const { region, type } = props

  const { data: bannersData, isError, isLoading, isSuccess } = useInputsPromoBannerApi(type, region)

  const { clickBannerGtmEvent, setRefCallback } = useIndexBannerGtmEvent(type === 'agricultural' ? 'blockBannerMain2' : 'blockBannerMain3')

  const shouldHideBlock = (isSuccess && !bannersData?.data?.banners.length) || isError

  if (shouldHideBlock) return null

  return (
    <SectionBlock>
      <PromoSliderBlock
        isLoading={isLoading}
        slideClassName="productsPromoBanners__slide"
        promoBanners={bannersData?.data?.banners}
        visibleItems={1}
        onViewCardRefCallback={setRefCallback}
        onBannerClickGtmEvent={clickBannerGtmEvent}
      />
    </SectionBlock>
  )
}

export default memo(ProductsPromoBanner)
