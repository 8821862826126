import React, { memo } from 'react'
import { useCatalogInteractionGtmEvent } from '@/logic/metrika/index/catalog/catalogSection'
import { useSelectCategoryGtmEvent } from '@/logic/metrika/index/catalog/categorySelector'
import { useOnProductCardsViewGtmEvent, useProductCardInteractGtmEvents } from '@/logic/metrika/index/catalog/productCards'
import { AllItemsButton, CardSliderBlock, CategorySelector, SectionBlock } from '@/pages/IndexPage/components'
import CatalogHeader from '@/pages/IndexPage/sections/Inputs/components/CatalogHeader/CatalogHeader'
import { useMainPageCatalog } from '../hooks'
import type { IProductCatalogProps } from './types'
import './styles.scss'

const ProductsCatalog = (props: IProductCatalogProps) => {
  const { type } = props

  const { categories, products, shouldHideBlock, changeCategory, selectedCategory, categoryIndex, isCategoriesLoading, isProductsLoading } =
    useMainPageCatalog(type)

  const blockName = type === 'agroproducts' ? 'mainFarming' : 'mainMachinery'
  const eventName = type === 'agroproducts' ? 'blockInputsFarming' : 'blockInputsMachinery'

  const setRefCallback = useOnProductCardsViewGtmEvent({ products, blockName })
  const { onAllItemsClickEvent } = useCatalogInteractionGtmEvent(eventName)
  const { inputsGtmEvents } = useProductCardInteractGtmEvents({ blockName })
  const { onCategorySelectGtmEvent } = useSelectCategoryGtmEvent(eventName)

  if (shouldHideBlock) return null

  const onSelectCategory = (index: number) => {
    changeCategory(index)

    if (selectedCategory) onCategorySelectGtmEvent(selectedCategory.id)
  }

  return (
    <>
      <CatalogHeader type={type} />
      <SectionBlock withoutRightMarginMobile className="productsCatalog__catalog">
        <CategorySelector
          isLoading={isCategoriesLoading}
          type="inputs"
          currentCategory={categoryIndex}
          setCategory={onSelectCategory}
          productsCount={categories?.productsCount}
          categories={categories?.categories}
        />

        <CardSliderBlock
          isLoading={isProductsLoading}
          products={products}
          gtmEvents={{ onViewCardRefCallback: setRefCallback, ...inputsGtmEvents }}
        />
      </SectionBlock>
      <SectionBlock>{selectedCategory && <AllItemsButton category={selectedCategory} onClick={onAllItemsClickEvent} />}</SectionBlock>
    </>
  )
}

export default memo(ProductsCatalog)
