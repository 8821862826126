import type { FC } from 'react'
import React, { memo } from 'react'
import { BestOffersInputs, BestOffersOutputs } from './components'

const BestOffers: FC = () => (
  <>
    <BestOffersInputs />
    <BestOffersOutputs />
  </>
)

export default memo(BestOffers)
