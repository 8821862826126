import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getCatalogMainPage, getCatalogMainPageQueryKey, getCategoryProducts, getCategoryProductsQueryKey } from '@/api/kubik/monolith'
import { useAuthenticateUser } from '@/components/ui/AuthButton/hooks'
import { PRODUCT_CATEGORIES } from '@/logic/index/constants'
import type { TCatalogType } from '@/logic/index/types'

export const useMainPageCatalog = (type: TCatalogType) => {
  const [category, setCategory] = useState<number>(0)
  const { isAuthenticated } = useAuthenticateUser()

  const {
    data: categories,
    isLoading: isCategoriesLoading,
    isError: isCategoriesError,
  } = useQuery({
    queryFn: () =>
      getCategoryProducts(
        { categories: PRODUCT_CATEGORIES[type] },
        {
          paramsSerializer: { indexes: null },
        },
      ),
    queryKey: getCategoryProductsQueryKey({ categories: PRODUCT_CATEGORIES[type] }),
    select: result => ({
      categories: result.data?.categories.sort((a, b) => (a.position || 0) - (b.position || 0)),
      productsCount: result.data?.countByGroup,
      total: result.data?.total,
    }),
  })

  const {
    data: products,
    isLoading: isProductsLoading,
    isError: isProductsError,
  } = useQuery({
    queryFn: () => getCatalogMainPage({ category: categories?.categories?.[category]?.id, limit: 16 }),
    queryKey: [...getCatalogMainPageQueryKey({ category: categories?.categories?.[category]?.id, limit: 16 }), isAuthenticated],
    select: result => result.data?.products,
    enabled: !!categories?.categories,
  })

  const shouldHideBlock = isProductsError && isCategoriesError

  return {
    changeCategory: setCategory,
    selectedCategory: categories?.categories?.[category],
    categoryIndex: category,
    shouldHideBlock,
    products,
    categories,
    isProductsLoading,
    isCategoriesLoading,
  }
}
